import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import MortgageCalculator from "../../components/MortgageCalculator";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const Kreditaufstockung = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Wie funktioniert eine Kreditaufstockung?" showCalc={false} />
            <Article>
                <p>
                    Wenn du gerade über die Möglichkeit einer Kreditaufstockung nachdenkst, solltest du über einige
                    Dinge informiert sein. Die Aufstockung eines bestehenden Kredits durch Umschuldung hat viele
                    Vorteile, ist aber nicht immer die beste Option. In diesem Artikel erfährst du, was du machen
                    kannst, wenn das Geld knapp wird.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Was sind die Voraussetzungen?</h2>
                <p>
                    Vor einer Kreditaufstockung ist es wichtig, dass du genau weißt, welche Geldsumme du wirklich
                    brauchst und ob du dir einen höheren Kredit überhaupt leisten kannst. Es passiert schnell, dass man
                    die eigene Situation falsch einschätzt. Deshalb solltest du dir ausreichend Zeit bei der Berechnung
                    deiner Möglichkeiten lassen und immer einen Puffer haben. Danach wird die Bank entscheiden, ob sie
                    dir unter deinen Voraussetzungen eine höhere Kreditsumme gewährt. Bei der Kreditaufstockung spielt
                    wie auch bei jedem Kredit deine Bonität eine wichtige Rolle. Du brauchst ein regelmäßiges Einkommen
                    und gewisse Sicherheiten, die du der Bank bieten kannst.
                </p>
                <hr />

                <h2>Die Umschuldung</h2>
                <p>
                    Durch die{" "}
                    <Link to="/dienstleistungen/umschuldung/" target="_blank" rel="noreferrer noopener">
                        Umschuldung
                    </Link>{" "}
                    deines bestehenden Kredits kann dieser „aufgestockt“ werden. Einen bestehenden Kredit einfach um
                    eine zusätzliche Summe zu erweitern, ist nicht möglich. Hinter jedem Kredit steht eine vertragliche
                    Vereinbarung, die Bonität wurde für eine bestimmte Summe geprüft. Deshalb muss die Bank wieder von
                    vorne beginnen und deine Zahlungsfähigkeit für den neuen Betrag feststellen. Wenn diese besteht,
                    kann ein neuer Vertrag vereinbart werden. Eine Umschuldung ist in vielen Fällen ratsam, vor allem
                    wenn du deinen ersten Kredit unter ungünstigen Konditionen abgeschlossen hast. Die Umschuldung
                    bedeutet nichts anderes, als einen bestehenden Kreditvertrag zu lösen und die gleiche oder eine
                    andere Summe wieder neu aufzunehmen. Das macht man meistens bei einem anderen Kreditinstitut. Bei
                    einer Kreditaufstockung würdest du also einen neuen Kreditvertrag aufnehmen, wofür du nicht zwingend
                    zu einer anderen Bank wechseln musst.
                </p>
                <hr />

                <h2>Einen neuen Kredit aufnehmen</h2>
                <p>
                    Auch mit einem laufenden Kredit kann man noch einen weiteren aufnehmen. Hier ist wie immer ein{" "}
                    <Link to="/dienstleistungen/kreditrechner/" target="_blank" rel="noreferrer noopener">
                        Kreditvergleich
                    </Link>{" "}
                    ratsam. Damit ist eine Kreditaufstockung möglich, doch es stellt sich die Frage nach den
                    Sicherheiten. Da du deinen laufenden Kredit vermutlich mit einer{" "}
                    <Link to="/artikel/hypothekenkredit/" target="_blank" rel="noreferrer noopener">
                        Hypothek
                    </Link>{" "}
                    abgesichert hast, ist die Bank mit dem Pfandrecht im{" "}
                    <Link to="/artikel/grundbuchauszug/" target="_blank" rel="noreferrer noopener">
                        Grundbuch
                    </Link>{" "}
                    eingetragen. Wenn jetzt aber noch ein neues eingetragen werden soll, wird es ein wenig schwierig.
                </p>
                <p>
                    Zum einen muss deine Immobilie neu bewertet werden, zum anderen ist die Bank, die den zweiten Kredit
                    vergibt, im Grundbuch an zweiter Stelle. Das könnte es recht schwierig machen, eine Bank zu finden,
                    die dir einen weiteren Kredit vergeben möchte. Das kann jedoch umgangen werden, indem du deinen
                    zweiten Kredit bei derselben Bank aufnimmst.
                </p>
                <hr />

                <h2>Was ist beim Zweitkredit zu beachten?</h2>
                <p>
                    Die Konditionen deines neuen Kredits werden nicht nur von der Sicherheit abhängig sein, sondern auch
                    von der Höhe des Kredits. Überlege dir deshalb wirklich gut, wieviel du brauchst. Bei der
                    Kreditaufstockung spielt es auch eine Rolle, wieviel von einem ersten Kredit du schon abbezahlt
                    hast.
                </p>
                <p>
                    Welche Art von Zweitkredit du für deine Kreditaufstockung am besten aufnehmen solltest, hängt ganz
                    von deinem individuellen Finanzierungsbedarf ab. Wenn du zum Beispiel gerade ein Haus baust, ist ein
                    weiterer{" "}
                    <Link to="/artikel/baufinanzierungsberater/" target="_blank" rel="noreferrer noopener">
                        Baukredit
                    </Link>{" "}
                    die beste Lösung. Hier solltest du dich unbedingt über verschiedene Förderungen informieren. Wenn du
                    das Geld für die Einrichtung benötigst, ist ein Konsumkredit die einfachere Variante. Diese sind
                    zwar recht teuer, aber man braucht dafür keine Sicherheiten. Wenn die Kreditaufstockung erst viele
                    Jahre nach dem ersten Kredit benötigt wird, wenn du zum Beispiel deine Immobilie ausbauen möchtest,
                    ist ein Baukredit viel einfacher zu bekommen, als am Anfang. Du hast bereits einen großen Anteil
                    deines Kredits zurückgezahlt, was heißt, dass die zweite Bank eher eine Sicherheit bekommt, da ihr
                    Recht im Grundbuch bald an erster Stelle gereiht wird.{" "}
                </p>
                <hr />

                <h2>Und wenn es um zusätzliche Immobilien geht?</h2>
                <p>
                    Hier sieht die Sache etwas anders aus. Wenn du einen zweiten Kredit für eine neue Immobilie
                    aufnehmen möchtest, kann dir die neue Immobilie als Sicherheit dienen. Dein Haushaltsbudget muss
                    trotzdem positiv sein. Auch die Mieteinnahmen werden hier einberechnet, wenn du die Wohnung
                    vermieten solltest. Es gibt allerdings einen Abschlag für Rücklagen und Mietausfälle.
                </p>
                <hr />

                <h2>Fazit</h2>
                <p>
                    Es gibt also verschiedene Möglichkeiten einer Kreditaufstockung. Ob du eine Umschuldung vornehmen
                    oder einen zweiten Kredit aufnehmen möchtest, muss vorher gut überlegt sein. In beiden Fällen musst
                    du dir die Aufstockung leisten können und dich mit dem Thema Bonität und Sicherheiten
                    auseinandersetzen.Dabei spielt deine individuelle Situation eine wichtige Rolle. Informiere dich gut
                    über die beste Option für dich und lasse dich professionell beraten.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
            </Article>
            <MortgageCalculator noTopMargin defaultTerm={35} defaultMortgage={50000} />

            <BreadcrumbList page={"kreditaufstockung"}></BreadcrumbList>
            <ArticleStructuredData page={"kreditaufstockung"} heading={"Wie funktioniert eine Kreditaufstockung?"} />
        </Layout>
    );
};

export default Kreditaufstockung;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "page.artikel.kreditaufstockung", "mortgageCalc"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
